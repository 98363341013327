const hostAppModule = {
  name: "HostApp",
  port: 3000,
  startMSW: true,
  fileName: "remote.main.js",
};

const creLeadsAppModule = {
  name: "creLeads",
  port: 3001,
  startMSW: true,
  appRouteName: "leads-ui",
  fileName: "remote.main.js",
  get federationConfig() {
    return `${this.name}@[window.uiConfig.creLeadUi.leadUiAppUrl]/remote.main.js?[Date.now()]`;
  },
};

module.exports = {
  hostAppModule,
   creLeadsAppModule,
};
