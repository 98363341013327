import { hostAppModule } from "../build-utils/moduleConfig";
import cookies from "../node_modules/js-cookie/dist/js.cookie.js";
console.log("NODE_ENV:", process.env.NODE_ENV);

(async function () {
  // process.env.NODE_ENV is special. webpack would ignore the require in this if statement and not including msw in the js bundle
  if (process.env.NODE_ENV === "development") {
    window["uiConfig"] = require("../msw/mocks/data/ui-config.json");
    if (hostAppModule.startMSW) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { worker } = require("../msw/mocks/browser");
      await worker.start({
        onUnhandledRequest: "bypass",
      });
    }
  } else {
    const response = await fetch("/ui-config");
    const config = await response.json();
    //To Validate the Blue Green Deployement
    let leadsapiCookie = cookies.get("creleads_leadsapi");
    let leadsUICookie = cookies.get("creleads_leadsui");
    if (leadsapiCookie == "validate") {
      updatePath(config, "leadServiceUrl", "");
    }
    if (leadsUICookie == "validate") {
      updatePath(config, "leadUiAppUrl", "");
    }
    window["uiConfig"] = config;
  }

  import("./shared/Bootstrap");
})();

function updatePath(config, appKey) {
  if (config.creLeadHostUi && config.creLeadHostUi[appKey]) {
    config.creLeadHostUi[appKey] = config.creLeadHostUi[appKey] + '/validation';
  }
  if (config.creLeadUi && config.creLeadUi[appKey]) {
    config.creLeadUi[appKey] = config.creLeadUi[appKey] + '/validation';
  }
}