import { rest } from "msw";
import { getLeadServiceUrl } from "../../../src/config";
import apiKeys from "../data/apikeys.json";
import uiConfigData from "../data/ui-config.json";

const handlers = [
  rest.get(`${getLeadServiceUrl()}/api/v1/lookup/apikey`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(apiKeys));
  }),
  rest.get(`/ui-config`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(uiConfigData));
  }),
];
export default handlers;
