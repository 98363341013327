/* eslint-disable @typescript-eslint/no-explicit-any */

interface Config {
  creLeadHostUi: {
    leadServiceUrl: string;
    leadUiAppUrl: string;
    env: string;
    enableDatadog: string;
    enableDatadogSessionRecording: string;
  };
}

const getConfig = () => {
  if ((window as any)["uiConfig"]) {
    return (window as any)["uiConfig"] as Config;
  }
  else{
    throw new Error("UI Configs are not loaded. Failed to load the App");
  }
};
export const getEnv = () => getConfig().creLeadHostUi.env;
export const getLeadServiceUrl = () => getConfig().creLeadHostUi.leadServiceUrl;
export const getLeadUiAppUrl = () => getConfig().creLeadHostUi.leadUiAppUrl;
export const hideHeaderFooter = () => window.location.href.indexOf("/marketinglead") > -1;

export const featureFlags = {
  isDatadogEnabled: (getConfig().creLeadHostUi?.enableDatadog.toLowerCase() ?? "false") !== "false",
  isDatadogSessionRecordingEnabled: (getConfig().creLeadHostUi?.enableDatadogSessionRecording.toLowerCase() ?? "false")
      !== "false",
};
